import * as React from "react";
import PropTypes from "prop-types";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const HomeContactForm = (props) => {
  const { formName } = props;
  const [state, setState] = React.useState({ message: "" });
  const [formError, setFormError] = React.useState("");
  const [sent, setSentBoolean] = React.useState(false)

  const handleChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { phone, email, message, fullName } = state;
    if ((!phone.trim() || !email.trim()) && (!message.trim() && !fullName.trim())) {
      setFormError("Please check the form for any errors.");
    }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formName,
        ...state,
      }),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          setState({});
          setSentBoolean(true)
        } else {
          alert("There was an error sending your email, please try again later.");
        }
      })
      .catch((error) => alert(error));
  };
  return (
    <form
      data-netlify="true"
      name={formName}
      method="post"
      onSubmit={handleSubmit}
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value={formName} />
      <div className="hidden">
        <label>Don&apos;t fill this out if you&apos;re human:</label>
        <input name="bot-field" />
      </div>
      <div className="relative mb-4">
        <label htmlFor="fullName" className="leading-7 text-sm text-gray-600">Full Name</label>
        <input onChange={handleChange} type="text" id="fullName" name="fullName" value={state.fullName || ""} required className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
      </div>
      <div className="relative mb-4">
        <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
        <input onChange={handleChange} type="email" id="email" name="email" value={state.email || ""} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
      </div>
      <div className="relative mb-4">
        <label htmlFor="phone" className="leading-7 text-sm text-gray-600">Phone Number</label>
        <input onChange={handleChange} type="tel" id="phone" name="phone" value={state.phone || ""} pattern="[0-9]{3}[0-9]{3}[0-9]{4}" required className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
      </div>
      <div className="relative mb-4">
        <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
        <textarea onChange={handleChange} type="message" id="message" value={state.message || ""} name="message" required className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
      </div>
      {!sent ? <button className="text-white bg-indigo-500 border-0 py-2 px-8 mx-8 focus:outline-none hover:bg-indigo-600 rounded text-lg" type={"submit"}>Submit</button> : <p>Thank you for contacting us. We'll get back to you as soon as possible.</p>}
      {formError && <p>{formError}</p>}
    </form>
  );
};


HomeContactForm.propTypes = {
  formName: PropTypes.string.isRequired
};

HomeContactForm.defaultProps = {
  formName: "userMessageFromHome"
};

export default HomeContactForm;
