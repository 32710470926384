import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Carousel } from "../carousel";

const Card = ({ title, content, queriedImages }) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (window !== undefined) {
      const images = [];
      queriedImages.edges.map((artwork) => images.push(artwork.node.childImageSharp.gatsbyImageData));
      const data = [];
      images.map((image) => data.push(
        <div className={"flex w-full justify-center items-center overflow-hidden"} key={image.images.fallback.src}>
          <GatsbyImage
            image={image}
            objectFit={"contain"}
            className={"shrink max-h-72 md:max-h-96 shadow-md"}
            alt={"some description"}
          />
        </div>
      ));
      setImages(data);
    };
  }, [queriedImages]);

  return (
    <div className="flex max-w-full lg:w-1/2 xl:w-1/3 p-2 mx-auto">
      <div className="flex w-full p-2 md:px-5 rounded-[20px] bg-white shadow-lg hover:shadow-xl mb-8">
        <div className="flex-auto w-full flex-col max-w-sm md:max-w-2xl">
          <Carousel className={"px-2"}>
            {images}
          </Carousel>
          <h4 className="font-semibold text-xl text-dark mb-3 px-4">{title}</h4>
          <p className="text-body-color p-4">{content}</p>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  queriedImages: PropTypes.any
};

export default Card;
