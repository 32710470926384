import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Carousel } from "../carousel";

const Hero = () => {
  const [images, setImages] = useState([]);

  const { allFile: { edges = [] } } = useStaticQuery(
    graphql`
      query {
          allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "heroPhotos"}}
          ) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(layout: FIXED)
                }
              }
            }
          }
        }
    `
  );

  useEffect(() => {
    if (window !== undefined) {
      const wideImages = [];
      edges.length > 0 && edges.map(({ node }) => wideImages.push(node.childImageSharp.gatsbyImageData));
      const data = [];
      wideImages.map((image) => data.push(
        <div className={"flex w-full justify-center items-center overflow-hidden"} key={image.images.fallback.src}>
          <GatsbyImage
            image={image}
            className={"shrink max-h-[40rem] md:max-h-[45rem] carouselImage"}
            alt={image.originalName}
          />
        </div>
      ));
      setImages(data);
    };
  }, [edges]);

  return (
    <div className={"flex w-full max-w-full heroContainer"}>
      <Carousel className={"heroCarousel shadow-xl"} showThumbs={false}>
        {images}
      </Carousel>
      <div className="pb-10 border-b-2 md:flex-row" style={{ height: "1px", boxSizing: "border-box", border: "0 solid #e0e0e0" }} />
    </div>
  );
};

Hero.propTypes = {
};

Hero.defaultProps = {
};


export default Hero;
