import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const carousel = (props) => {
    return (
        <Carousel
            showArrows
            // showStatus
            // showIndicators
            showThumbs={props.showThumbs}
            infiniteLoop
            // autoPlay
            emulateTouch={false}
            swipeable={false}
            stopOnHover
            className={props.className}
            interval={"5000"}
            {...props.style}
        >
            {props.children}
        </Carousel>
    );
};

export default carousel;
