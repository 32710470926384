import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Carousel } from "../carousel";

const AboutUsHomeSection = () => {
  const [images, setImages] = useState([]);

  const { allFile: { edges = [] } } = useStaticQuery(
    graphql`
      query {
          allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "maintenance"}}
          ) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(layout: FIXED)
                }
              }
            }
          }
        }
    `
  );

  useEffect(() => {
    if (window !== undefined) {
      const wideImages = [];
      edges.length > 0 && edges.map(({ node }) => wideImages.push(node.childImageSharp.gatsbyImageData));
      const data = [];
      wideImages.map((image) => data.push(
        <div className={"flex justify-center items-center overflow-hidden"} key={image.images.fallback.src}>
          <GatsbyImage
            image={image}
            className={"shrink max-h-80"}
            alt={"some desc"}
          />
        </div>
      ));
      setImages(data);
    };
  }, [edges]);

  return (
    <section className={"aboutContainer"}>
      <div className="flex max-w-full flex-col p-2 justify-center items-center pb-10 border-b-2 md:flex-row">
        <div className="flex flex-col mt-5 w-full justify-center max-w-xl md:px-10 md:w-1/2 items-center">
          <div className="text-2xl font-black uppercase md:text-5xl text-primary">
            <span className="">Reliable, high quality work</span>
          </div>
          <div className="mt-4 text-xl">
            Founded in 2020, Paraiso Landscaping has expanded in experience and knowledge for projects that meet customer needs.
          </div>
          <div className="h-16 my-5 mt-10">
            <Link to="/about" className="w-48 px-4 py-2 text-lg font-medium text-center text-white rounded shadow-md cursor-pointer hover:underline hover:text-green-700 bg-green-500">
              Learn Our Story
            </Link>
          </div>
        </div>
        <div className="flex w-full mt-5 justify-center md:justify-end md:w-1/2">
          <div className="z-10 max-w-full w-full sm:max-w-md mt-6 md:ml-4 border-2 rounded-full shadow-lg hover:shadow-xl">
            <Carousel showThumbs={false}>
              {images}
            </Carousel>
            <div className="p-10 text-lg bg-white">
              ”Success is no accident. It is hard work, perseverance, learning, studying, sacrifice and most of all, love of what you are doing or learning to do.”
              <p className="mt-4 text-xl">- Pele</p>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

AboutUsHomeSection.propTypes = {};

export default AboutUsHomeSection;
