import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import { SEO } from "../components/seo";
import Hero from "../components/hero";
import AboutUsHomeSection from "../components/AboutUsHomeSection";
import ServicesSectionHomePage from "../components/ServicesSectionHomePage";
import { ContactUs } from "../components/contactUs";

const IndexPage = ({ location }) => {
  const { site } = useStaticQuery(graphql`
    query indexPageInfo {
      site {
        siteMetadata {
          title
          companyNumber
          companyEmail
        }
      }
    }
  `);
  return (
    <Layout location={location} site={site}>
      <SEO title={`${site.siteMetadata.title} - Home`} />
      <Hero {...site.siteMetadata} />
      <AboutUsHomeSection {...site.siteMetadata} />
      <ServicesSectionHomePage {...site.siteMetadata} />
      <ContactUs {...site.siteMetadata} />
    </Layout >
  );
};

IndexPage.propTypes = {
  location: PropTypes.any.isRequired
};

IndexPage.defaultPropTypes = {
  location: {}
};

export default IndexPage;
