import * as React from "react";
import PropTypes from "prop-types";
import { HeaderOne, HeaderTwo } from "../headers";
import HomeContactForm from "../form";

const contactUs = ({ companyEmail, companyNumber }) => (
  <section className="text-gray-600 body-font border-b-2">
    <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
      <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
        <HeaderOne text={"We are a family owned business where we seek satisfaction of our clients with the help and inspiration of nature."} />
        <p className="leading-relaxed mt-4">We can make your yard complete the home of your dreams, thanks in advance for reaching out to us to include us in the process of making your vision come to fruition. A big thanks to all our clients for supporting us and providing jobs to our team, our family.</p>
        <p className="leading-relaxed mt-4">Our office number is {companyNumber}, feel free to reach out with a call or a text, we're available Mon-Sat 8 A.M. to 5 P.M. or you can send us an email at <a href={`mailto:${companyEmail}`}>{companyEmail}.</a> and we will reach out as soon as possible.</p>
      </div>
      <div className="lg:w-2/6 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 shadow-lg hover:shadow-xl">
        <HeaderTwo text={"Inquire us to see what we can do for you."} />
        <HomeContactForm />
      </div>
    </div>
  </section >
);

contactUs.propTypes = {
  companyEmail: PropTypes.string,
  companyNumber: PropTypes.string,
};

contactUs.defaultProps = {
  companyEmail: "",
  companyNumber: ""
};

export default contactUs;
