import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Card from "../cards";

const ServicesSectionHomePage = () => {
  const { retaingWallPics, fencingPics, patioPics, maintenancePics, pressureWashingPics, artificialGrassPics } = useStaticQuery(graphql`
  query {
      retaingWallPics: allFile(
        filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "retaining_wall"}}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      },
      fencingPics: allFile(
        filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "fencing"}}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      },
      patioPics: allFile(
        filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "patios"}}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      },
      maintenancePics: allFile(
        filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "maintenance"}}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      },
      pressureWashingPics: allFile(
        filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "pressure_washing"}}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      },
      artificialGrassPics: allFile(
        filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "artifical_grass"}}
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      }
    }
  `);
  return (
    <section className="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] border-b-2">
      <div className="flex max-full mx-auto">
        <div className="flex w-full flex-wrap flex-column md:flex-row max-full mx-auto">
          <div className="flex w-full px-2">
            <div className="text-center mx-auto mb-12 lg:mb-20 max-w-[510px]">
              <span className="font-semibold text-lg text-primary mb-2 block">
                Our Services
              </span>
              <h2 className="font-bold text-3xl sm:text-4xl md:text-[40px] text-dark mb-4" >
                What We Offer
              </h2>
            </div>
          </div>
          <Card title={"Patios"} content={"We offer different designs, sizes, shapes and colors."} queriedImages={patioPics} />
          <Card title={"Maintenance"} content={"Providing a variety of maintence services, from trimming plants and tree, to replacing old soil, bark or mulch. Contact us about helping get rid of weeds in your yard."} queriedImages={maintenancePics} />
          <Card title={"Retaining Walls"} content={"We can help build the patio of your dreams up to 4ft tall. With a variety of block shapes, sizes, and colors. We'll help build the perfect retaining wall to go along with your patio."} queriedImages={retaingWallPics} />
          <Card title={"Fencing"} content={"Different styles and gates, 4+ years of experience in this field."} queriedImages={fencingPics} />
          {/* <Card title={"Irrigation"} content={"From a variety of zones and sprinklers, to drip lines that water your trees and plants as need, in a safe, professional and clean way."} queriedImages={pressureWashingPics} /> */}
          <Card title={"Turf"} content={"Let us can help you decide from the various options available, ex. short grass, long grass, realistic turf, soft or hard. Contact us for more infromation."} queriedImages={artificialGrassPics} />
        </div>
      </div>
    </section>
  );
};

ServicesSectionHomePage.propTypes = {};

export default ServicesSectionHomePage;
