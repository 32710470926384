import * as React from "react";
import PropTypes from "prop-types";

export const HeaderOne = ({ text }) => (<h1 className="title-font font-medium text-3xl text-gray-900">{text}</h1>);

HeaderOne.propTypes = {
  text: PropTypes.string.isRequired
};

HeaderOne.defaultProps = {
  text: ""
};

export const HeaderTwo = ({ text }) => (<h2 className="text-gray-900 text-lg font-medium title-font mb-5">{text}</h2>);

HeaderTwo.propTypes = {
  text: PropTypes.string.isRequired
};

HeaderTwo.defaultProps = {
  text: ""
};
